import { notify } from '@root/shared/utils/notification';
import { useCallback, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { signInWithGoogleService } from '../services';
import { authSlice } from '../store';
import { useAccessCode } from './use-access-code';
import { accessCode } from './use-sign-up';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IAuth } from '../types';
import { setCookie } from '@root/shared/utils/cookies';
import { TwoFAResponse } from '../types/two-fa';

export const useSignInWithGoogle = (setTwoFaResponse?: (response) => void) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onClose } = useAccessCode();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const onSuccess = useCallback(
    async (response) => {
      if (response?.access_token) {
        const result = await signInWithGoogleService(response.access_token, accessCode);
        if (result.status === 200) {
          if (result.payload.isTwoFactorEnabled) {
            setTwoFaResponse?.(result.payload.twoFactorResponse as TwoFAResponse);
            if (result.payload.twoFactorResponse?.accessToken) {
              // TODO: accessToken issues
              // setCookie(
              //   'twoFaAccessToken',
              //   result.payload.twoFactorResponse.accessToken,
              //   Date.now() + 24 * 60 * 60 * 1000 * 200,
              // );
              localStorage.setItem('twoFaAccessToken', result.payload.twoFactorResponse.accessToken);
            }
          } else {
            const session = result.payload.session as IAuth;
            dispatch(authSlice.actions.signedInWithGoogle(result.payload.session as IAuth));

            const next = decodeURIComponent(searchParams.get('next') || '');
            if (session.userSubscriptionInfo.roles?.length) {
              if (next && next !== '/') {
                navigate(next);
              } else {
                navigate('/');
              }
            } else {
              navigate(`/subscriptions`);
            }
          }
        }

        if (result.status === 400) {
          notify({
            type: 'danger',
            title: result.payload,
          });
        }
      }
      setIsLoading(false);
    },
    [setTwoFaResponse, dispatch, searchParams, navigate],
  );

  const onFailure = useCallback(() => {
    setIsLoading(false);
  }, []);

  const login = useGoogleLogin({
    onSuccess,
    onError: onFailure,
  });

  const signIn = useCallback(() => {
    setIsLoading(true);
    login();
  }, [login]);

  return { signIn, isLoading, isReady: true, isOpen, onClose };
};
