import { Socket, io } from 'socket.io-client';

import { updateTokens } from '@root/shared-files/modules/auth/helpers';
import { refreshTokenService } from '@root/shared-files/modules/auth/services';
import { getCookie } from '@root/shared/utils/cookies';

const url = import.meta.env.VITE_API_HOST;

const maxRetries = 3;
let retries = 0;

export const createSocket = (): Socket => {
  const socket = io(url, {
    path: '/websockets/socket.io',
    transports: ['websocket'],
    auth: {
      // TODO: accessToken issues
      token: localStorage.getItem('accessToken'),
    },
  });

  socket.on('connect', () => {
    retries = 0;
    console.log('socket connect');
    socket.emit('init', {});
  });

  socket.on('connect_error', () => {
    setTimeout(() => {
      socket.connect();
    }, 1000);
  });

  socket.on('connect_error', async (e) => {
    console.log('TOKEN SOCKET', e);
    if (retries < maxRetries) {
      // TODO: accessToken issues
      const refreshToken = localStorage.getItem('refreshToken');
      // const refreshToken = getCookie('refreshToken');

      if (refreshToken) {
        const response = await refreshTokenService(refreshToken);

        if (response.status === 200) {
          const { accessToken, refreshToken } = response.payload.tokens;
          updateTokens(accessToken, refreshToken);
          socket.auth['token'] = accessToken;
          socket.connect();
        }
      }
      retries++;
    }
  });

  return socket;
};
