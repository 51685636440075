import { getCookie } from '@root/shared/utils/cookies';

export const getTokens = () => {
  // TODO: accessToken issues
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  // const accessToken = getCookie('accessToken');
  // const refreshToken = getCookie('refreshToken');

  return { accessToken, refreshToken };
};
