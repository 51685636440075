import { Store } from '@reduxjs/toolkit';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { updateTokens } from '@root/shared-files/modules/auth/helpers';
import { refreshTokenService } from '@root/shared-files/modules/auth/services';
import { authSlice } from '@root/shared-files/modules/auth/store';
import { getCookie } from '@root/shared/utils/cookies';

import { queryClient } from '../query';

let store: Store | null = null;

export const injectStore = (_store: Store) => {
  store = _store;
};

const addAuthorizationToken = (headers: any = {}, token?: string) => {
  headers['Authorization'] = `Bearer ${token}`;
};

export const fetcherRequestInterceptor = {
  onFulfilled: (config: AxiosRequestConfig) => {
    // TODO: accessToken issues
    // const accessToken = getCookie('accessToken');
    // const twoFaAccessToken = getCookie('twoFaAccessToken');
    const accessToken = localStorage.getItem('accessToken');
    const twoFaAccessToken = localStorage.getItem('twoFaAccessToken');

    if (twoFaAccessToken || accessToken) {
      if (!config.headers) {
        config.headers = {};
      }

      config.headers['Authorization'] = `Bearer ${twoFaAccessToken || accessToken}`;
    }

    return config;
  },
};


let isRefreshingTokens = false;
let requestsQueue = [] as any;

export const fetcherResponseInterceptor = {
  onRejected: async (error: AxiosError) => {
    if (store) {
      const request = error.config;
      const isRetried = (request as any)._retry;
      const isNotUnauthorized = error.response?.status === 401;
      // TODO: accessToken issues
      const refreshToken = localStorage.getItem('refreshToken');
      // const refreshToken = getCookie('refreshToken');

      if (isNotUnauthorized) {
        if (!isRefreshingTokens) {
          store.dispatch(authSlice.actions.setIsTokensRefreshing(true));
          isRefreshingTokens = true;

          if (!!refreshToken && !isRetried) {
            const response = await refreshTokenService(refreshToken);

            if (response.status === 200) {
              (request as any)._retry = true;
              const { accessToken, refreshToken } = response.payload.tokens;
              updateTokens(accessToken, refreshToken);
              addAuthorizationToken(request.headers, accessToken);

              store.dispatch(authSlice.actions.tokensRefreshed(response.payload));

              requestsQueue.forEach((cb: (p: string) => void) => cb(accessToken));
              requestsQueue = [];
              store.dispatch(authSlice.actions.setIsTokensRefreshing(false));
              isRefreshingTokens = false;

              return axios.request(request);
            }
          }
          store.dispatch(authSlice.actions.setIsTokensRefreshing(false));
          isRefreshingTokens = false;
          queryClient.clear();
          store.dispatch(authSlice.actions.signedOut());
        } else {
          return new Promise((resolve) => {
            requestsQueue.push((accessToken: string) => {
              request.headers!['Authorization'] = `Bearer ${accessToken}`;
              resolve(axios.request(request));
            });
          });
        }
      }
    }
    return Promise.reject(error);
  },
};
