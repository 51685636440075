import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AbsolutePortal } from '@root/infra/portals';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { helpLinks } from '@root/shared/constants/social-links';
import { WriteMessageIcon } from '@root/shared/icons/write-message-icon';
import { BalanceWidget } from '@root/shared/ui/financial-widgets';
import { Text } from '@root/shared/ui/typography';
import { externalRedirect } from '@root/shared/utils/helpers/external-redirect';

import { LayoutElements } from '../components';
import { useDropdown } from '../hooks/use-dropdown';
import { useSubscriptionTitles } from '../hooks/use-subscription-titles';
import { MenuItem } from './header.elements';
import { GenieGptIcon } from '@root/shared/icons/genie-gpt';
import { SettingsIcon } from '@root/shared/icons/settings-icon';
import { CheckTwoFaBadge } from '@root/shared-files/modules/auth/components/two-fa/check-two-fa-enabled';
import { LogoutIcon } from '@root/shared/icons/log-out';
import { StockIcon } from '@root/shared/icons/stock-icon';
import { FxIcon } from '@root/shared/icons/fx-icon';
import { CryptoIcon } from '@root/shared/icons/crypto-icon';
import { DashboardIcon } from '@root/shared/icons/dashboard-icon';
import { BoldIcon } from '@root/shared/icons/bold-icon';
import { ExpertsIcon } from '@root/shared/icons/experts-icon';
import { ProvidersIcon } from '@root/shared/icons/providers-icon';
import { SignalsIcon } from '@root/shared/icons/signals-icon';
import { ReferralIcon } from '@root/shared/icons/referral-icon';
import { SubscriptionsIcon } from '@root/shared/icons/subscriptions-icon';
import { ScannersIcon } from '@root/shared/icons/scanners-icon';
import { staticMenu } from '../containers';

const { MobileContainer, MobileLinksContainer } = LayoutElements.Header;

export const MobileHeaderMenu: FC<{ toggleMenu: () => void }> = ({ toggleMenu }) => {
  const { t } = useTranslation('common');
  const { title, subTitle, icon, className } = useSubscriptionTitles();
  const Icon = icon;

  const isVerified = useSelector(authSelector.isEmailVerified);
  const user = useSelector(authSelector.user);

  const [, { logout }] = useDropdown();

  const { isGhostLogin } = useGhostLogin();

  const dispatch = useDispatch();

  const handleOpenSendFeedBack = () => {
    toggleMenu();
    dispatch(userSettingsSlice.actions.setFeedbackModal(true));
  };

  const redirectToCryptoSite = useCallback(() => {
    externalRedirect(import.meta.env.VITE_CRYPTO_SITE, { target: '_blank' });
  }, []);

  const redirectToGpt = useCallback(() => {
    externalRedirect(import.meta.env.VITE_CHAT_LINK, { target: '_blank' });
  }, []);

  return (
    <AbsolutePortal>
      <MobileContainer isGhostLogin={isGhostLogin}>
        <div>
          {isVerified && <BalanceWidget wrapperClassName='shadow-lg' />}

          <MobileLinksContainer className='px-2 sm:px-4 pt-4'>
            <div className='mb-4'>
              <div className='flex items-center pl-3'>
                <span className='flex flex-col'>
                  <Text className='font-medium'>{title}</Text>
                  <Text size='sm'>{user?.email as string}</Text>
                  {subTitle && (
                    <div className={clsx('flex items-center gap-x-2', className)}>
                      {Icon ? <Icon /> : null}
                      <div className='mt-1'>{subTitle}</div>
                    </div>
                  )}
                </span>
              </div>
              <div className='pl-4'>
                <div className="flex justify-between items-center">
                  <MenuItem link={'/settings'}>
                    <SettingsIcon />{' '}
                    <Text size='sm' className='font-semibold'>
                      {t('header.dropdown.settings')}
                    </Text>
                  </MenuItem>
                  <div onClick={toggleMenu}>
                    <CheckTwoFaBadge />
                  </div>
                </div>
                <MenuItem onClick={logout}>
                  <LogoutIcon />
                  <Text size='sm' className='font-semibold'>
                    {t('header.dropdown.logout')}
                  </Text>
                </MenuItem>
              </div>
            </div>

            <div className='mb-2'>
              <MenuItem link={PageLinks.home}>
                <FxIcon />
                <Text size='sm' className='font-semibold'>
                  {t('sideBar.fx')}
                </Text>
              </MenuItem>
              <div className='pl-4'>
                <MenuItem link={PageLinks.home}>
                  <DashboardIcon />
                  <Text size='sm' className='font-semibold'>
                    {t('sideBar.dashboard')}
                  </Text>
                </MenuItem>
                <MenuItem link={PageLinks.accounts}>
                  <BoldIcon />
                  <Text size='sm' className='font-semibold'>
                    {t('sideBar.accounts')}
                  </Text>
                </MenuItem>
                <MenuItem link={PageLinks.experts}>
                  <ExpertsIcon />
                  <Text size='sm' className='font-semibold'>
                    {t('sideBar.experts')}
                  </Text>
                </MenuItem>
                {/*<MenuItem link={PageLinks.symbolRemap}>*/}
                {/*  <SvgSpriteIcon id='transfer' />*/}
                {/*  <Text size='sm' className='font-semibold'>*/}
                {/*    {t('sideBar.remap')}*/}
                {/*  </Text>*/}
                {/*</MenuItem>*/}
              </div>
              <MenuItem link={PageLinks.providers}>
                <Text size='sm' className='font-semibold'>
                  {t('sideBar.marketplace')}
                </Text>
              </MenuItem>
              <div className='pl-4'>
                <MenuItem link={PageLinks.providers}>
                  <ProvidersIcon />
                  <Text size='sm' className='font-semibold'>
                    {t('sideBar.providers')}
                  </Text>
                </MenuItem>
                <MenuItem link={PageLinks.presets}>
                  <SignalsIcon />
                  <Text size='sm' className='font-semibold'>
                    {t('sideBar.presets')}
                  </Text>
                </MenuItem>
              </div>
            </div>

            <div className='mb-2'>
              <MenuItem>
                <ScannersIcon />
                <Text size='sm' className='font-semibold'>
                  {t('sideBar.scanners')}
                </Text>
              </MenuItem>
              <div className='pl-4'>
                {staticMenu.scanners.items.map((item, index) => (
                  <MenuItem key={index} link={item.url}>
                    <item.Icon />
                    <Text size='sm' className='font-semibold'>
                      {t(item.title)}
                    </Text>
                  </MenuItem>
                ))}
              </div>
            </div>

            {/*<div className='mb-2'>*/}
            {/*  <MenuItem link={'/ai-assist/simple'}>*/}
            {/*    <GeoLocateIcon />*/}
            {/*    <Text size='sm' className='font-semibold'>*/}
            {/*      {t('sideBar.simple')}*/}
            {/*    </Text>*/}
            {/*  </MenuItem>*/}
            {/*  <MenuItem link={'/ai-assist/dca'}>*/}
            {/*    <ArrowGoUpIcon />*/}
            {/*    <Text size='sm' className='font-semibold'>*/}
            {/*      {t('sideBar.dca')}*/}
            {/*    </Text>*/}
            {/*  </MenuItem>*/}
            {/*  <MenuItem link={'/ai-assist/grid'}>*/}
            {/*    <GridMenuIcon />*/}
            {/*    <Text size='sm' className='font-semibold'>*/}
            {/*      {t('sideBar.grid')}*/}
            {/*    </Text>*/}
            {/*  </MenuItem>*/}
            {/*  <MenuItem link={'/trade-flow'}>*/}
            {/*    <TargetIcon width={26} height={26} />*/}
            {/*    <Text size='sm' className='font-semibold'>*/}
            {/*      {t('sideBar.smartTrade')}*/}
            {/*    </Text>*/}
            {/*  </MenuItem>*/}
            {/*</div>*/}

            <div className='mb-2'>
              <MenuItem link={'/subscriptions'}>
                <SubscriptionsIcon />
                <Text size='sm' className='font-semibold'>
                  {t('sideBar.subscriptions')}
                </Text>
              </MenuItem>
              <MenuItem link={'/referral'}>
                <ReferralIcon />
                <Text size='sm' className='font-semibold'>
                  {t('sideBar.referral')}
                </Text>
              </MenuItem>
            </div>

            {/* <div className='mb-2'>
              <MenuItem link={'/fx'} isActive={pathname.startsWith('/fx')}>
                <FXIcon />
                <Text size='sm' className='font-semibold'>
                  {t('sideBar.fx')}
                </Text>
              </MenuItem>
            </div> */}

            <div className='mb-2' onClick={redirectToCryptoSite}>
              <MenuItem>
                <CryptoIcon />
                <Text size='sm' className='font-semibold'>
                  {t('sideBar.crypto')}
                </Text>
              </MenuItem>
            </div>

            <div className='mb-2' onClick={redirectToGpt}>
              <MenuItem>
                <GenieGptIcon />
                <Text size='sm' className='font-semibold'>
                  {t('sideBar.masterGpt')}
                </Text>
              </MenuItem>
            </div>

            {/* <div className='mb-2 opacity-50 pointer-events-none'>
              <MenuItem link={'/stock'}>
                <StockIcon />
                <Text size='sm' className='font-semibold'>
                  {t('sideBar.stock')}
                </Text>
              </MenuItem>
            </div> */}
          </MobileLinksContainer>
        </div>

        <div className='w-full'>
          {/* {isVerified && (
            <Extend>
              <div className='flex flex-shrink overflow-x-auto w-full flex-wrap justify-between items-center bg-grayscale-800 shadow-lg p-4 pt-4 gap-4 max-h-20 overflow-auto'>
                <PriceWidget title='BTC/USD' value={5412} currency='$' percent={52.05} />
                <PriceWidget title='BTC/BUSD' value={5123} currency='$' percent={52.05} />
                <PriceWidget title='BTC/TET' value={2151} currency='$' percent={-23.12} />
              </div>
            </Extend>
          )} */}

          {/* <div className='flex items-center px-4 justify-center'>
            <Item title={t('sideBar.subscriptions')} icon={<SettingsIcon />} />
            <Item title={t('sideBar.referral')} icon={<UsersIcon />} />
          </div> */}

          <div className='flex items-center px-4 pb-4 justify-center'>
            {/* <HelpItem>
              <BellIcon />
            </HelpItem>
            <HelpItem>
              <ConversationIcon />
            </HelpItem>
            <HelpItem>
              <ConnectedPeopleIcon />
            </HelpItem> */}
            <a href={helpLinks.submitFeature} className='flex items-center gap-x-2 hover:text-primary-400' target='_blank' rel='noreferrer'>
              <WriteMessageIcon />
            </a>
          </div>
        </div>
      </MobileContainer>
    </AbsolutePortal>
  );
};
